import React, { Suspense, useEffect, useLayoutEffect } from 'react'
import {
  Box,
  ChakraProvider,
  theme,
  Spinner,
  Skeleton,
  useColorMode,
} from '@chakra-ui/react'
import './App.css'
import {
  Exclusive,
  Footer,
  Header,
  Whyus,
  Artists,
  Privacy,VerifyEmail,
  NeedMore,
  VideoModal,
  Terms,
  Goup,
  ArtistDashboard,
  ClientDashboard,
  AdminDashboard,
  Home,
  Categories,
  Error,EmailModal,
  Featured,
  SingleArtist,
  Booking,
  Enquire,
  LoginMain,
  RegisterMain,
  ForgotMain,
  ResetMain,
  About,
  FAQ,
  Cookies,
} from './Components'
import { Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react'
import AppStore from './Store/Appstore'
import { HideLandingLoader } from './Components/Common/commonfn'
import Appstore from './Store/Appstore'
import { hot } from 'react-hot-loader/root'
import ErrorBoundary from './Components/ErrorBoundary'
import CustomSwitch from './CustomProgressBar'


const App = observer(() => {
  // const { colorMode, toggleColorMode } = useColorMode()
  useLayoutEffect(() => {
    const handleresize = async e => {
      const width = window.innerWidth
      if (width < 1021) {
        AppStore.setDevice(0)
      } else {
        AppStore.setDevice(1)
      }
    }

    if (!AppStore.isTesting) {
      console.log = () => {}
      console.info = () => {}
    }
    handleresize()
    const scroller = async e => {
      const element = document.getElementsByClassName('mainHeader')[0]
      if (window.scrollY > (element.offsetTop + element.offsetHeight) * 2) {
        console.log('scroller')
        AppStore.setFooter(true)
      } else {
        AppStore.setFooter(false)
      }
    }

    window.addEventListener('resize', handleresize, { passive: true })
    window.addEventListener('scroll', scroller, { passive: true })
    return () => {
      window.removeEventListener('resize', handleresize)
      window.removeEventListener('scroll', scroller)
    }
  }, [])
  if (localStorage.getItem('chakra-ui-color-mode'))
    Appstore.setMode(localStorage.getItem('chakra-ui-color-mode'))
  useEffect(() => {
    window.scrollTo(0, 0)
    setTimeout(HideLandingLoader(), 5000)
  }, [])
  const goupOnclick = async () => {
    await document
      .getElementsByClassName('mainHeader')
      .scrollIntoView({ block: 'end', behavior: 'smooth' })
  }
  return (
    <ChakraProvider theme={theme}>
      <Suspense fallback={<Skeleton height={50} width="100%" />}>
        <Header />
      </Suspense>
      <Suspense fallback={<Skeleton height={50} width="100%" />}>
        <Goup onClick={goupOnclick} />
      </Suspense>

      <Suspense>
        <CustomSwitch>
          <Route path="*" element={<Error />} />
          <Route
            exact
            path="/"
            element={
              <>
                {/* <ErrorBoundary fallback="There was error in app we wil get back asap."> */}
                <Suspense
                  fallback={
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                  }
                >
                  <Home />
                </Suspense>
                {/* </ErrorBoundary> */}
                <ErrorBoundary fallback="There was error in app we wil get back asap.">
                  <Suspense>
                    <Categories />
                  </Suspense>
                </ErrorBoundary>
                <ErrorBoundary fallback="There was error in app we wil get back asap.">
                  <Suspense>
                    <Exclusive />

                    {/* <Whyus /> */}
                  </Suspense>
                </ErrorBoundary>
                <ErrorBoundary fallback="There was error in app we wil get back asap.">
                  <Suspense fallback={<Skeleton height={450} width="100%" />}>
                    <Featured />
                  </Suspense>
                </ErrorBoundary>

                <ErrorBoundary fallback="There was error in app we wil get back asap.">
                  <Suspense>
                    <FAQ />
                  </Suspense>
                </ErrorBoundary>
                <ErrorBoundary fallback="There was error in app we wil get back asap.">
                  <Suspense>
                    <NeedMore />
                  </Suspense>
                </ErrorBoundary>
              </>
            }
          />
          <Route
            exact
            path="/FAQ"
            element={
              <>
                <ErrorBoundary fallback="There was error in app we wil get back asap.">
                  <Suspense fallback={<Skeleton height={550} width="100%" />}>
                    <Box mt={10}>
                      <FAQ />
                      <NeedMore />
                    </Box>
                  </Suspense>
                </ErrorBoundary>
              </>
            }
          />
          <Route
            exact
            path="/artists"
            element={
              <>
                <ErrorBoundary fallback="There was error in app we wil get back asap.">
                  <Suspense fallback={<Skeleton height={850} width="100%" />}>
                    <Artists />
                  </Suspense>
                </ErrorBoundary>
                <ErrorBoundary fallback="There was error in Needmore section we wil get back asap.">
                  <Suspense fallback={<Skeleton height={850} width="100%" />}>
                    <NeedMore />
                  </Suspense>
                </ErrorBoundary>
              </>
            }
          />
          <Route
            exact
            path="/artists/:id"
            element={
              <>
                <ErrorBoundary fallback="There was error in app we wil get back asap.">
                  <Suspense fallback={<Skeleton height={650} width="100%" />}>
                    <SingleArtist />
                  </Suspense>
                </ErrorBoundary>
              </>
            }
          />
          <Route
            exact
            path="/about-us"
            element={
              <ErrorBoundary fallback="There was error in app we wil get back asap.">
                <Suspense fallback={<Skeleton height={650} width="100%" />}>
                  <About />
                </Suspense>
              </ErrorBoundary>
            }
          />

          <Route
            exact
            path="/booking/:id"
            element={
              <ErrorBoundary fallback="There was error in app we wil get back asap.">
                <Suspense fallback={<Skeleton height={950} width="100%" />}>
                  <Booking />
                </Suspense>
              </ErrorBoundary>
            }
          />

          <Route
            exact
            path="/artistdashboard"
            element={
              <>
                <Suspense fallback={<Skeleton height={450} width="100%" />}>
                  <ArtistDashboard />
                </Suspense>
                <Suspense>
                  <VideoModal />
                </Suspense>
              </>
            }
          />

          <Route
            exact
            path="/clientdashboard"
            element={
              <>
                <Suspense fallback={<Skeleton height={850} width="100%" />}>
                  <ClientDashboard />
                </Suspense>
                <Suspense>
                  <VideoModal />
                </Suspense>
              </>
            }
          />
          <Route
            exact
            path="/admindashboard"
            element={
              <>
                <Suspense fallback={<Skeleton height={850} width="100%" />}>
                  <AdminDashboard />
                </Suspense>
                <Suspense>
                  <VideoModal />
                </Suspense>
              </>
            }
          />

          <Route
            exact
            path="/privacy-policy"
            element={
              <ErrorBoundary fallback="There was error in app we wil get back asap.">
                <Suspense fallback={<Skeleton height={850} width="100%" />}>
                  <Privacy />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            exact
            path="/terms-conditions"
            element={
              <ErrorBoundary fallback="There was error in app we wil get back asap.">
                <Suspense fallback={<Skeleton height={850} width="100%" />}>
                  <Terms />
                </Suspense>
              </ErrorBoundary>
            }
          />

          <Route
            exact
            path="/enquire"
            element={
              <ErrorBoundary fallback="There was error in app we wil get back asap.">
                <Suspense fallback={<Skeleton height={650} width="100%" />}>
                  <Enquire />
                </Suspense>
              </ErrorBoundary>
            }
          />
        </CustomSwitch>

        <ErrorBoundary fallback="There was error registration app we wil get back asap.">
          <Suspense>
            <RegisterMain />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary fallback="There was login in app we wil get back asap.">
          <Suspense>
            <EmailModal />
            <LoginMain />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary fallback="There was reset password in app we wil get back asap.">
          <Suspense>
            <ResetMain />
            {/* <VerifyEmail /> */}
          </Suspense>
        </ErrorBoundary>
        {/* <ErrorBoundary fallback="There was reset password in app we wil get back asap."> */}
        <Suspense>
          {/* <ResetMain /> */}
          <VerifyEmail />
        </Suspense>
        {/* </ErrorBoundary> */}

        <ErrorBoundary fallback="There was error in app we wil get back asap.">
          <Suspense>
            <ForgotMain />
          </Suspense>
        </ErrorBoundary>
      </Suspense>
      <Suspense fallback={<Skeleton height={450} width="100%" />}>
        <Footer />
      </Suspense>

      <Suspense fallback={<Skeleton height={250} width="100%" />}>
        <Cookies />
      </Suspense>
    </ChakraProvider>
  )
})
export default hot(App)
