import { useToast } from '@chakra-ui/react'
import React from 'react'

export const HideLandingLoader = () => {
  document.querySelector('.Spinner_Background').style.display = 'none'
  var spinner = document.querySelector('body').classList
  if (spinner.contains('Spinner_Default')) spinner.remove('Spinner_Default')
  document.querySelector('html').style.overflow = ''
}

export const ErrorToaster = e => {
  const toast = useToast()
  toast({
    title: e,
    variant: 'solid',
    status: 'error',
    position: 'top-right',
    isClosable: true,
  })
}
export const InfoToaster = e => {
  const toast = useToast()
  toast({
    title: e,
    variant: 'solid',
    status: 'info',
    position: 'top-right',
    isClosable: true,
  })
}
export const SuccessToaster = e => {
  const toast = useToast()
  toast({
    title: e,
    variant: 'solid',
    status: 'success',
    position: 'top-right',
    isClosable: true,
  })
}
