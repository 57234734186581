import { ColorModeScript } from '@chakra-ui/react'
import React from 'react'
import * as ReactDOM from 'react-dom/client'
import App from './App'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { theme } from './theme'
import ErrorBoundary from './Components/ErrorBoundary'

const container = document.getElementById('root')
const queryClient = new QueryClient()
// if (container.hasChildNodes()) {
//   const root = ReactDOM.hydrateRoot(
//     container,
//     <BrowserRouter>
//       <ColorModeScript />
//       <QueryClientProvider client={queryClient}>
//         <App />
//       </QueryClientProvider>
//     </BrowserRouter>
//   )
// } else {
const root = ReactDOM.createRoot(container)
root.render(
  <BrowserRouter theme={theme}>
    <ColorModeScript />
    <QueryClientProvider client={queryClient}>
      {/* <ErrorBoundary fallback="There was error in app we wil get back asap."> */}
      <App />
      {/* </ErrorBoundary> */}
    </QueryClientProvider>
  </BrowserRouter>
)
// }
