import { makeAutoObservable, toJS } from 'mobx'
class AppStore {
  isTesting =
    window.location.href.includes('Testing') ||
    window.location.href.includes('localhost')
      ? true
      : false
  isLocalhost = window.location.href.includes('localhost')
  device = 0
  footer = false
  mode = 'dark'
  info = []
  register = false
  people = 50
  cookies = false
  admindb = false

  clientdb = false
  forgotpw = false
  updateData = false
  
  finalData = false
  videoModal=false
  paymentModal = false
  
  updateV = false
  login = false
  apilink = window.location.href.includes('localhost')
    ? 'http://localhost:2000'
    : 'https://vagreya-backend.vercel.app'
  resetPassword = window.location.href.includes('resetPassword') ? true : false
  verifyEmail = window.location.href.includes('verifyEmail') ? true : false
  uploadImagedModal = false
  finalDate = 1
  emailVerificationModal = false
  starttime = null
  endtime = null
  startDate = null
  admininfo = []
  adminBookingdb=false
  adminBookinginfo=[]
  bookinginfo = []
  endDate = null
  payment = 8000
  GST = 18
  aadharModal=false
  panModal=false
  constructor() {
    makeAutoObservable(this)
  }
  setAadharModal=data=>(this.aadharModal = data)
  setPanModal=data=>(this.panModal = data)
  setInfo = data => toJS((this.info = data))
  setEmailVerificationModal=data=>(this.emailVerificationModal=data)
  setAdminInfo = data => toJS((this.admininfo = data))
  setAdminDB = data => (this.admindb = data)
  setVideoModal = data => (this.videoModal = data)
  
  setAdminBookingInfo = data => toJS((this.adminBookinginfo = data))
  setAdminBookingDB = data => (this.adminBookingdb = data)
  setFinalDate = data => (this.finalDate = data)
  
  setPeople = data => (this.people = data)

  setClientDB = data => (this.clientdb = data)
  setPaymentModal = data => (this.paymentModal = data)
  setClientInfo = data => toJS((this.bookinginfo = data))
  
  setFinalData = data => toJS((this.finalData = data))
  setReg = data => (this.register = data)
  setLogin = data => (this.login = data)
  setCookies = data => (this.cookies = data)
  setUpdateData = data => (this.updateData = data)
  setUpdateV = data => (this.updateV = data)

  setStartTime = data => (this.starttime = data)
  setEndTime = data => (this.endtime = data)

  setStartDate = data => (this.startDate = data)
  setEndDate = data => (this.endDate = data)
  setPayment = data => (this.payment = data)

  setUploadImages = data => (this.uploadImagedModal = data)
  setResetPassword = data => (this.resetPassword = data)

  setVerifyEmail = data => (this.verifyEmail = data)
  
  setForgotPassword = data => (this.forgotpw = data)
  setMode = data => (this.mode = data)
  setDevice = data => (this.device = data)
  setFooter = data => (this.footer = data)
}

export default new AppStore()
