import { lazy } from 'react'

const Header = lazy(() => import('./Header/index'))
const Home = lazy(() => import('./Home/index'))
const Footer = lazy(() => import('./Footer/index'))
const Exclusive = lazy(() => import('./Home/Exclusive/index'))
const NeedMore = lazy(() => import('./Home/NeedMore/index'))
const Error = lazy(() => import('./Common/Error'))
const Featured = lazy(() => import('./Home/Featured/index'))
const Categories = lazy(() => import('./Home/Categories/index'))
const Whyus = lazy(() => import('./Whyus/index'))
const Goup = lazy(() => import('./Common/Goup/index'))
const Artists = lazy(() => import('./Artist/multiple/artists'))
const SingleArtist = lazy(() => import('./Artist/single/index'))
const Booking = lazy(() => import('./Artist/booking/index'))
const ArtistDashboard = lazy(() => import('./dashboard/artist/index'))
const ClientDashboard = lazy(() => import('./dashboard/client/index'))
const AdminDashboard = lazy(() => import('./dashboard/admin/index'))

const LoginMain = lazy(() => import('./Login/login'))
const RegisterMain = lazy(() => import('./Register/register'))
const ForgotMain = lazy(() => import('./ForgotPassword/forgot'))
const ResetMain = lazy(() => import('./ResetPassword/reset'))
const VerifyEmail = lazy(() => import('./VerifyEmail/index'))

const About = lazy(() => import('./About/index'))
const Enquire = lazy(()=>import("./enquire/index"))
const Privacy = lazy(() => import('./Privacy/index'))

const Cookies = lazy(()=>import("./Cookies/index"))

const FAQ = lazy(()=>import("./Home/FAQ/index"))
const EmailModal = lazy(()=>import("./dashboard/EmailModal/index"))
const Terms=lazy(()=>import("./Terms/index"))
const VideoModal=lazy(()=>import("./dashboard/VideoModal/index"))
export {
  Booking,
  VideoModal,
  Terms,
  RegisterMain,VerifyEmail,
  ForgotMain,
  LoginMain,
  Cookies,EmailModal,
  FAQ,
  Privacy,
  AdminDashboard,
  About,
  ResetMain,
  Enquire,
  Error,
  Artists,
  ArtistDashboard,
  ClientDashboard,
  Whyus,
  SingleArtist,
  Featured,
  Goup,
  NeedMore,
  Home,
  Categories,
  Header,
  Footer,
  Exclusive,
}
